/**
 * 需求信息实体类和接口
 */
import { ReqPage } from '@/model/common/reqPage'
import Data from '../common/Data'
//同行信息类
export class Demand {
  id: string | undefined // "string //主键"
  demandNo: string | undefined // 需求编号"
  companyName: string | undefined //公司名称
  customerNo: string | undefined //客户编号
  salesman: string | undefined //业务员
  contact: string | undefined //联系人
  contactPhone: string | undefined //联系人手机号
  primaryIndustry: string | undefined //所属行业-一级行业
  secondaryIndustry: string | undefined //所属行业-二级行业
  demandProduct: string | undefined //需求产品名称
  deviceName: string | undefined //应用设备名称
  demandCount: string | undefined //预计需求数量
  useEnv: string | undefined //使用环境
  demandDate: string | undefined //需求时间
  dealStatus: number | undefined //0：待处理，1：已终止
  isDeleted: number | undefined //是否删除 0：未删除 1：已删除
  createdBy: string | undefined //创建人
  createdTime: string | undefined //创建时间
  updatedBy: string | undefined //更新人
  updatedTime: string | undefined //更新时间
  demandDesc: string | undefined //需求描述
  terminationReason: string | undefined //终止原因
  fileIdList: string | undefined //需求文件id集合
  isConverted: number | undefined //0：未转 1：已转
  isFollow: number | undefined //0：为关注 1：关注
}
//分页查询类
export class RegDemand extends ReqPage {
  data: Demand
  constructor() {
    super()
    this.data = new Demand()
  }
}
export interface ResDemandClass extends ReqPage {
  data: Demand[]
}
export interface ResDemandDetail {
  proposalVO: Demand
  copFileInfoVOList: Data[]
}
