
import { defineComponent, toRefs, ref, computed, reactive, createVNode, nextTick } from 'vue'
import listLayout from '@/components/listLayout/listLayout.vue'
import FormItem from '@/components/formItem/index.vue'
import { Demand, RegDemand } from '@/model/industryManagement/demand'
import editButton from '@/components/editButton/index.vue'
import demandApi from '@/axios/api/demandApi'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import yTable from '@/components/yTable/index.vue'
import { deleteModal } from '@/utils/deleteModal'
import { Modal } from 'ant-design-vue'
import Data from '@/model/common/Data'
import AchieveApi from '@/axios/api/achievementApi'
import { FormItemListType } from '@/types/comm'
import Drawer from '@/components/Drawer/Drawer.vue'
import { ColumnsTypeData } from '@/components/Drawer/Drawer'
export default defineComponent({
  components: {
    listLayout,
    FormItem,
    editButton,
    yTable,
    Drawer,
  },
  setup() {
    /**
     * @description: 获取筛选后可展示表头列表
     * @param { Array<ColumnsTypeData> } data 当前所有表头列表
     * @return {*}
     */
    const updateColumns = (value: Array<ColumnsTypeData>) => {
      pageData.loading = true
      pageData.columns = value
      nextTick(() => {
        pageData.loading = false
      })
    }
    const peerTable = ref()
    //获取路由
    let router = useRouter()
    //获取store
    const store = useStore()
    const buttonObj = store.getters.getButtonObj
    const pageData = reactive({
      isShowDrawer: false, //列设置弹框展示控制
      selectFrom: new RegDemand(),
      dataSource: [] as Demand[],
      columns: [
        {
          title: '需求编号',
          dataIndex: 'demandNo',
          key: 'demandNo',
          width: 150,
          resizable: true,
        },
        {
          title: '公司名称',
          dataIndex: 'companyName',
          key: 'companyName',
          width: 120,
          resizable: true,
        },
        {
          title: '业务员',
          dataIndex: 'salesman',
          key: 'salesman',
          width: 120,
          resizable: true,
        },
        {
          title: '所属行业',
          dataIndex: 'primaryIndustry',
          key: 'primaryIndustry',
          width: 120,
          resizable: true,
        },
        {
          title: '需求产品',
          dataIndex: 'demandProduct',
          key: 'demandProduct',
          width: 100,
          resizable: true,
        },
        {
          title: '设备名称',
          dataIndex: 'deviceName',
          key: 'deviceName',
          width: 100,
          resizable: true,
        },
        {
          title: '联系人',
          dataIndex: 'contact',
          key: 'contact',
          width: 100,
          resizable: true,
        },
        {
          title: '处理状态',
          dataIndex: 'dealStatus',
          key: 'dealStatus',
          width: 100,
          statusObj: {
            0: '待处理',
            1: '已终止',
          },
          resizable: true,
        },
        {
          title: '发送状态',
          width: 100,
          dataIndex: 'isSend',
          resizable: true,
        },
        {
          title: '提交时间',
          dataIndex: 'createdTime',
          key: 'createdTime',
          width: 120,
          resizable: true,
        },
        {
          title: '操作',
          width: 100,
          dataIndex: 'operation',
          resizable: true,
        },
      ] as ColumnsTypeData[],
      selectedRowKeys: [] as string[],
      loading: false,
      formItemList: [
        {
          type: 'subInput',
          prop: 'demandNo',
          placeholder: '请输入需求编号',
          label: '需求编号',
        },
        {
          type: 'subInput',
          prop: 'companyName',
          placeholder: '请输入公司名称',
          label: '公司名称',
        },
        {
          type: 'subInput',
          prop: 'contact',
          placeholder: '请输入联系人',
          label: '联系人',
        },
        {
          type: 'subSelect',
          prop: 'isFollow',
          placeholder: '请选择是否关注',
          label: '是否关注:',
          optionList: [
            {
              value: 0,
              label: '未关注',
            },
            {
              value: 1,
              label: '关注',
            },
          ],
        },
        {
          type: 'slot',
          prop: 'primaryIndustry',
          placeholder: '请选择行业',
          label: '所属行业:',
        },
        {
          type: 'subInput',
          prop: 'demandProduct',
          placeholder: '请输入需求产品',
          label: '需求产品',
        },
        {
          type: 'subInput',
          prop: 'deviceName',
          placeholder: '请输入设备名称',
          label: '设备名称',
        },
        {
          type: 'subSelect',
          prop: 'dealStatus',
          placeholder: '请选择是否处理',
          label: '是否处理:',
          optionList: [
            {
              value: 0,
              label: '未处理',
            },
            {
              value: 1,
              label: '已终止',
            },
          ],
        },
      ] as FormItemListType<Demand>,
      stopVisible: false,
      stopForm: new Demand(),
      sendVisible: false,
      sendForm: new Demand(),
      primaryIndustry: [],
      options: [] as Data[],
    })
    //传入table的页面配置
    const currentConfigure = computed(() => {
      let page = {
        pageSize: pageData.selectFrom.pageSize,
        currentPage: pageData.selectFrom.currentPage,
        total: pageData.selectFrom.dataCount,
        onChange(page: number, pageSize: number) {
          pageData.selectFrom.pageSize = pageSize
          pageData.selectFrom.currentPage = page
          initeList()
        },
        onShowSizeChange(current: number, size: number) {
          pageData.selectFrom.pageSize = size
          pageData.selectFrom.currentPage = current
          initeList()
        },
      }
      return page
    })
    //获取表格信息
    const initeList = () => {
      pageData.loading = true
      pageData.selectedRowKeys.length = 0
      demandApi
        .GetProposalList(pageData.selectFrom)
        .then((res) => {
          pageData.dataSource = []
          if (res.data) {
            Object.assign(pageData.dataSource, res.data)
          }
          pageData.selectFrom.currentPage = res.currentPage as number
          pageData.selectFrom.pageSize = res.pageSize
          pageData.selectFrom.dataCount = res.dataCount
          pageData.loading = false
        })
        .catch((err) => {
          pageData.loading = false
          message.error(err)
        })
    }
    //重置数据
    const selectList = () => {
      pageData.selectFrom.currentPage = 1
      pageData.selectFrom.pageSize = 10
      initeList()
    }
    //查询数据
    selectList()
    //新增按钮
    const addData = () => {
      router.push('/industryManagement/demandAdd')
    }
    //编辑按钮
    const updateData = () => {
      if (pageData.selectedRowKeys.length !== 1) {
        message.warning('请选择一项数据！')
        return
      }
      router.push(`/competitiveAnalysis/peerData/updatePeerData/${pageData.selectedRowKeys[0]}`)
    }
    //删除按钮
    const delData = () => {
      if (pageData.selectedRowKeys.length === 0) {
        message.warning('至少选中一条')
        return
      }
      let data = pageData.selectedRowKeys.map((item) => {
        return {
          id: item,
        }
      })
      const ok = () => {
        demandApi
          .ProposalDelete(data)
          .then(() => {
            message.success('删除成功')
            initeList()
          })
          .catch((err) => {
            message.error(err)
          })
      }
      const onCancel = () => {
        console.log('Cancel')
      }
      deleteModal(ok, onCancel)
    }
    //跳转至账号设置页面
    const accountSet = (id: string, name: string) => {
      console.log(`跳转至id为${id}页面`)
      router.push({
        path: `/competitiveAnalysis/peerData/accountInformation/${id}`,
        query: { name },
      })
    }
    //搜索
    const search = () => {
      console.log(pageData.selectFrom)
    }
    //
    const reset = () => {
      pageData.selectFrom.data = new Demand()
      pageData.primaryIndustry = []
      selectList()
    }
    //打开关联
    const associated = () => {
      if (pageData.selectedRowKeys.length !== 1) {
        message.warning('请选中一条')
        return
      }
      // let accompanyId = (
      //   pageData.dataSource.find((item) => item.id === pageData.selectedRowKeys[0]) as PeerClass
      // ).companyCode as string
      router.push(
        '/competitiveAnalysis/peerData/associatedPeerInformation/' + pageData.selectedRowKeys[0]
      )
    }
    //终止
    const stopData = () => {
      if (pageData.selectedRowKeys.length !== 1) {
        message.warning('请选择其中一条数据！')
        return
      }
      let data = pageData.dataSource.find((item) => item.id === pageData.selectedRowKeys[0])
      pageData.stopForm = JSON.parse(JSON.stringify(data))
      pageData.stopVisible = true
    }
    const stopOk = () => {
      if (!pageData.stopForm.terminationReason) {
        message.warning('请填写终止原因！')
        return
      }
      demandApi
        .ProposalTerminate(pageData.stopForm)
        .then(() => {
          message.success('终止成功')
          pageData.stopVisible = false
          initeList()
        })
        .catch((err) => {
          message.error(err)
        })
    }
    //转提案
    const toDemand = () => {
      if (pageData.selectedRowKeys.length === 0 || pageData.selectedRowKeys.length > 10) {
        message.warning('请至少选择一项数据,最多十条！')
        return
      }
      Modal.confirm({
        title: () => '确认提示?',
        icon: () =>
          createVNode(
            'svg',
            {
              class: 'icon',
              'aria-hidden': 'true',
              style: 'margin-right:5px;font-size:16px;vertical-align:text-top;',
            },
            [createVNode('use', { 'xlink:href': '#icon-tishi_lvse' })]
          ),
        content: () => '是否转提案',
        onOk() {
          let data = pageData.selectedRowKeys.map((item) => {
            return { id: item }
          })
          demandApi
            .ProposalToDemand(data)
            .then(() => {
              message.success('成功')
              initeList()
            })
            .catch((err) => {
              message.error(err)
            })
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onCancel() {},
      })
    }
    const demandFollow = (id: string, isFollow: number) => {
      if (isFollow === 1) {
        demandApi
          .FollowCancel(id)
          .then(() => {
            message.success('取消关注成功')
            initeList()
          })
          .catch((err) => {
            message.error(err)
          })
      } else {
        demandApi
          .FollowData(id)
          .then(() => {
            message.success('关注成功')
            initeList()
          })
          .catch((err) => {
            message.error(err)
          })
      }
    }
    //行业改变事件
    const cascaderChange = (value: string[]) => {
      pageData.selectFrom.data.primaryIndustry = value[0]
      pageData.selectFrom.data.secondaryIndustry = value[1]
    }
    // 一级行业获取
    AchieveApi.queryOptionalType({ optionalType: 'primaryIndustry' })
      .then((res) => {
        if (res) {
          pageData.options = res.map((item) => {
            return {
              label: item.name,
              value: item.name,
              custom: item.id,
              isLeaf: false,
            }
          })
        }
      })
      .catch((err) => {
        message.error(err)
      })
    const loadData = (val: Data[]) => {
      console.log(val)

      AchieveApi.queryOptionalType({
        optionalType: 'secondaryIndustry',
        subParameter: val[0].custom,
        noLoading: true,
      })
        .then((res) => {
          val[0].children =
            res?.map((item) => {
              return {
                label: item.name,
                value: item.name,
                custom: item.id,
                isLeaf: true,
              }
            }) || []
        })
        .catch((err) => {
          message.error(err)
        })
    }
    const sendMsg = () => {
      if (pageData.selectedRowKeys.length !== 1) {
        message.warning('请选择其中一条数据！')
        return
      }
      let data = pageData.dataSource.find((item) => item.id === pageData.selectedRowKeys[0])
      if (data?.dealStatus !== 1) {
        message.warning('请选择已终止的数据！')
        return
      }
      pageData.sendForm = JSON.parse(JSON.stringify(data))
      pageData.sendVisible = true
    }
    const sendOk = () => {
      demandApi
        .SendMsg({ id: pageData.sendForm.id })
        .then(() => {
          message.success('发送成功')
          initeList()
          pageData.sendVisible = false
        })
        .catch((err) => {
          message.error(err)
        })
    }
    return {
      sendMsg,
      sendOk,
      loadData,
      cascaderChange,
      demandFollow,
      toDemand,
      stopOk,
      ...toRefs(pageData),
      addData,
      peerTable,
      accountSet,
      updateData,
      search,
      fallbackImg: require('@/assets/img/fallbackImg.svg'),
      selectList,
      delData,
      reset,
      buttonObj,
      currentConfigure,
      associated,
      labelCol: { style: { width: '100px' } },
      wrapperCol: { style: { width: '100%' } },
      stopData,
      updateColumns,
    }
  },
})
